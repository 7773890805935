import React from 'react';

import Layout from '../components/Layout';

//import about from '../assets/images/about.jpg';

const IndexPage = () => (
  <Layout activeLink="Flavours">
    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
              
              <h2 className="section-heading mb-5">
                <span className="section-heading-upper"></span>
                <span className="section-heading-lower">Cake Flavours</span>
              </h2>

              <table className="flavours">
                <tbody>
                  <tr>
                    <p>Vanilla</p>
                    {/* <i class="fas fa-birthday-cake"></i> */}
                    {/* <br></br> */}
                    <p>Lemon</p>
                    {/* <i class="fas fa-birthday-cake"></i> */}
                    {/* <br></br> */}
                    <p>Orange</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Rainbow</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Coconut</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Coffee</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Banana</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Carrot</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Red Velvett</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Chocolate Sponge</p>
                    {/* <tr><i class="fas fa-birthday-cake"></i></tr>
                    <br></br> */}
                    <p>Dark Chocolate Mud</p>
                    <p>White Chocolate Mud</p>
                    <p></p>
                    <tr><i class="fas fa-birthday-cake"></i></tr>
                    {/* <br></br> */}
                  </tr>
                </tbody>
             </table>

            </div>
          </div>

          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
              
              <h2 className="section-heading mb-5">
                <span className="section-heading-upper"></span>
                <span className="section-heading-lower">Cake Servings</span>
              </h2>

              <table className="servings">
                <tbody>
                  <tr>
                  <h3 class="servings">Price Guide</h3>
                    <p>As our cakes are custom made for you the price will be based on the size, shape & design you would like. Please fill in our enquiry form and we will get back to you with a quote.</p>
                    <p>Thank You!</p>
                    {/* <i class="fas fa-birthday-cake"></i> */}
                    <br></br>
                    <h3>Cakes</h3>
                    <p>Buttercream Icing: single tier 6" cake (serves 10-12) start from $120</p>
                    <p>Fondant Icing: start from $180</p>
                    <i class="fas fa-birthday-cake"></i>
                    {/* <br></br> */}
                    <h3 class="servings">Cupcakes</h3>
                    <p>Regular size (minimum order of 12)</p>
                    <p>Buttercream swirl sprinkles & colour of your choice $4 each</p>
                    <p>Buttercream with edible logo image $5 each</p>
                    <p>Buttercream with custom fondant topper from $7</p>
                    <i class="fas fa-birthday-cake"></i>
                    {/* <br></br> */}
                    <h3 class="servings">Mini Cupcakes</h3>
                    <p>Mini size (minimum order of 12)</p>
                    <p>Buttercream swirl sprinkles & colour of your choice $2 each</p>
                    <p>Buttercream with edible logo image $3 each</p>
                    <p>Buttercream with custom fondant topper from $5</p>
                    <i class="fas fa-birthday-cake"></i>
                    {/* <br></br> */}
                  </tr>
                </tbody>
             </table>

            </div>
          </div>


        </div>
      </div>
    </section>

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">

              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>
              
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
